<template>
<div class="view-home">

	<div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb pl-0">
        <li class="breadcrumb-item"><router-link to="/" tag="a">{{ $t('breadcrumb.main-page') }}</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.faq') }}</router-link></li>
      </ol>
    </nav>
    <div class="page-title text-lg-left text-center">
      {{ $t('faq.title') }}
    </div>
    <div class="row about-page pb-5">
      <div class="col-md-12">
        <div class="row">
  <!--          <div class="col-md-4 sidebar">-->
  <!--            <ul class="sidebar-menu">-->
  <!--              <li><a href="#">Общие вопросы</a></li>-->
  <!--              <li class="active"><a href="#">Олимпиада Myn Bala</a></li>-->
  <!--              <li><a href="#">Профориентация Qabilet</a></li>-->
  <!--              <li><a href="#">Онлайн школа Birge Oqy</a></li>-->
  <!--            </ul>-->
  <!--          </div>-->
          <div class="col-md-12 content">
            <div id="accordion">
              <div class="card" v-for="(data, key) in accordionData">
                <div class="card-header" @click="accordion = 'collapse' + key" :id="'heading' + key">
                  <h5 class="mb-0">
                    <button class="btn btn-link" :class="{'active': accordion == 'collapse' + key}" data-toggle="collapse" :data-target="'#collapse' + key" aria-expanded="false" :aria-controls="'collapse' + key">
                      {{ data.title }}
                    </button>
                  </h5>
                </div>

                <div :id="'collapse' + key" :class="{'show': accordion == 'collapse' + key}" class="collapse" :aria-labelledby="'heading' + key" data-parent="#accordion">
                  <div class="card-body pt-0">
                    <hr class="mt-0">
                    <div v-html="$t(data.content + data.content2)"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {
      accordion: 'collapse0',
      accordionData: [
        {
          'title': this.$t('faq.question-1'),
          'content': this.$t('faq.answer-1'),
          'content2': this.$t('faq.answer-1-1')
        },
        {
          'title': this.$t('faq.question-2'),
          'content': this.$t('faq.answer-2'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-3'),
          'content': this.$t('faq.answer-3'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-4'),
          'content': this.$t('faq.answer-4'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-5'),
          'content': this.$t('faq.answer-5'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-6'),
          'content': this.$t('faq.answer-6'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-7'),
          'content': this.$t('faq.answer-7'),
          'content2': ''
        },
        {
          'title': this.$t('faq.question-8'),
          'content': this.$t('faq.answer-8'),
          'content2': ''
        }
      ],
    }
  },
}
</script>
